import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import Section from '~components/Section'
import Slider from '~components/Slider'
import { Global, css } from '@emotion/react'
import Tile from '~components/Tile'
import { mobile } from '~styles/global'
import resolveLink from '~utils/resolveLink'
import {	Header, Heading, Color, Subtitle, StyledSlider, Left, Right, Next, NextRow, NextLink, NextText, RightArrow } from '~styles/gradProdStyles'

const Production = ({ data }) => {
	
	const { page } = data
	const productions = data?.allSanityProduction?.nodes
	const currentIndex = productions?.findIndex(p => p?.slug?.current === page?.slug?.current)
	const nextProduction = currentIndex < (productions?.length - 1) ? productions[currentIndex + 1] : null

	return(
		<>
			<Global 
				styles={css`
					:root{
						--featureColor: ${page?.course[0]?.keyColour};
					}
				`}
			/>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<DataViewer data={page} name="page"/>
			<Header>
				<Heading>
					<Color color={page.course[0]?.keyColour}>
						{page.title}
					</Color>
					<Subtitle>
						{page.subtitle}
					</Subtitle>
				</Heading>
			</Header>
			<StyledSlider slides={page.media}/>
			<Content>
				<Left className='h4'>
					<RichText content={page.credits}/>
				</Left>
				<Right>
					<RichText content={page.text}/>
				</Right>
			</Content>
			<Graduates>
				<GraduatesTitle color={page.course[0]?.keyColour} >Graduates</GraduatesTitle>
				{page?.graduates?.map(g => (
					<Tile g={g.graduate} role={g.role} key={g.graduate?.title} color={g.graduate?.course[0]?.keyColour}/>
				))}
			</Graduates>
			{nextProduction &&
				<Next>
					<NextRow className='h1'>
						<NextLink to={resolveLink(nextProduction)} >
							<NextText>Next Production &amp; Project</NextText><RightArrow />
						</NextLink>
					</NextRow>
				</Next>
			}
		</>
	)
}

const Content = styled(Section)`
	margin-bottom: 240px;
	${mobile}{
		margin-bottom: 118px;
	}
`
const Graduates = styled(Section)`
	margin-bottom: 190px;
	>div{
		grid-column-gap: 0px;
		${mobile}{
			grid-column-gap: 0px;
		}
	}
	${mobile}{
		margin-bottom: 98px;
	}
`
const GraduatesTitle = styled.h1`
	grid-column: span 12;
	color: ${ props => props.color };
	margin-bottom: 40px;
`

Production.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query ProductionQuery($slug: String) {
    page: sanityProduction(slug: {current: {eq: $slug}}) {
			title
			slug {
				current
			}
			subtitle
			course {
				title
				keyColour
			}
			media {
				... on SanityImageWithCaption {
					_key
					_type
					...imageWithCaption
					asset {
						metadata {
							dimensions {
								aspectRatio
								width
								height
							}
						}
					}
				}
				... on SanityVideoWithCaption {
					_key
					_type
					...videoWithCaption
				}
			}
			credits: _rawCredits(resolveReferences: {maxDepth: 8})
			text: _rawText(resolveReferences: {maxDepth: 8})
			graduates {
				role
				graduate {
					...graduateThumb
				}
			}
    }
		allSanityProduction {
			nodes {
				_type
				slug{
					current
				}
			}
		}
  }
`

export default Production