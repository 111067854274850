import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'

const Tile = ({ className, g, color, index, role }) => {

	const [hover, setHover] = useState(false)

	// const hoverColor = color ? color : g?.course[0]?.keyColour
	const hoverColor = color ? color : ''

	return (
		<Wrap className={className}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<Link to={resolveLink(g)}>
				<Images>
					<Image image={g.thumbnailImage} aspectRatio={1} lazyLoad threshold={index * 0.1}/>
					<Headshot image={g.headshot} hover={hover} aspectRatio={1} lazyLoad relative={!g.thumbnailImage}/>
				</Images>
				<Title hover={hover} color={hoverColor} italic={g._type === 'production'}>{g.title}</Title>
				<Description className='h4'>{role ? role : g.description}</Description>
			</Link>
		</Wrap>
	)
}

const Wrap = styled.div`
	grid-column: span 3;
	margin-bottom: 68px;
	${tablet}{
		grid-column: span 6;
		margin-bottom: 34px;
	}
`
const Images = styled.div`
	position: relative;
`
const Headshot = styled(Image)`
	position: ${ props => props.relative ? 'relative' : 'absolute'};
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: ${ props => props.hover ? '1' : '0'};
	transition: opacity 0.25s;
`
const Title = styled.div`
	margin-top: 20px;
	color: ${ props => props.hover ? props.color : 'inherit'};
	transition: color 0.25s;
	margin-right: 20px;
	${mobile}{
		margin-top: 13px;
	}
`
const Description = styled.div`
	margin-right: 20px;
`

Tile.propTypes = {
	className: PropTypes.string,
	g: PropTypes.object,
	color: PropTypes.string,
	index: PropTypes.number,
	role: PropTypes.string,
}

export default Tile